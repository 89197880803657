<template>
    <circle fill="none" class="st0" cx="21" cy="21" r="19.5"/>
    <path stroke="none" id="Path_9" d="M23,24h-4V11h4V24z M23,30.9h-4v-3.5h4V30.9z"/>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "icon-attention"
})
</script>