<template>
  <div class="controls">
    <div
      class="controls__content"
      v-html="controls"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"
import marked from 'marked'
import DOMPurify from 'dompurify'

export default defineComponent({
  name: "controls",
  props: {
    artwork: Object
  },

  setup(props) {
    const convertToHtml = (markdown) => {
      if(!markdown) return
      const converted = marked(markdown, { breaks: true })
      return DOMPurify.sanitize(converted, {ALLOWED_TAGS: ['a', 'p', 'strong', 'em', 'h1', 'h2', 'h3', 'h4', 'table', 'br']})
    }

    const controls = computed(() => {
      if(!props.artwork) return
      return convertToHtml(props.artwork.controls)
    })

    return {
      controls
    }
  },
})
</script>
