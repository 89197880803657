<template>
  <div
    class="artwork"
    v-if="artwork"
    >
    <Details class="artwork__details" :artwork="artwork" :media="media" />
    <ul class="artwork__tab-bar">
      <li
        class="artwork__tab-bar__item"
        :class="{ 'is-active': currentTab === tab.name }"
        v-for="tab in tabs"
        :key="tab.label"
        @click="changeTab(tab)"
        >
        {{ tab.label }}
      </li>
    </ul>
    <Artwork v-show="currentTab === 'artwork'" />
    <keep-alive>
      <component :is="isComponent" class="artwork__tab-container" :media="media"></component>
    </keep-alive>
  </div>
</template>

<script>
import { computed, defineComponent, watch, ref} from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import Details from "./ArtworkDetails.vue"
import Artwork from "./Artwork.vue"
import BuySection from "./BuySection.vue"
import Info from "./Info.vue"

export default defineComponent({
  components: {
    Details,
    Artwork,
    BuySection,
    Info,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const tabs = ref([
      {
        name: Artwork.name,
        label: 'artwork'
      },
      {
        name: Info.name,
        label: 'info'
      },
      {
        name: BuySection.name,
        label: 'buy'
      }
    ])

    const currentTab = ref(Artwork.name)
    const changeTab = tab => currentTab.value = tab.name

    const isComponent = computed(() => {
      if (currentTab.value !== "artwork") {
        return currentTab.value
      }
    })

    const loadArtworkFromFirebase = (artworkURI) =>
      store.dispatch("artwork/loadFromFirebase", artworkURI)
    const requestMedia = () => store.dispatch("artwork/market/requestMedia")
    const reset = () => store.dispatch("artwork/reset")

    const artwork = computed(() => store.getters["artwork/getData"])
    const media = computed(() => store.state.artwork.market.media)
    const artworkUID = computed(() => store.getters["artwork/getId"])
    const ethAddress = computed(() => store.state.user.account)

    const loadLatestSave = () => {
      const saveID = route.params.saveID

      if (saveID) {
        store.dispatch('artwork/optionsV2/loadFromID', saveID)
      } else {
        // load users latest load
        store.dispatch("artwork/optionsV2/loadFromFirebase", {
          ethAddress: ethAddress.value,
          artwork: { uid: artworkUID.value },
          slot: "0",
          cache: true,
        })
      }
    }

    const chainId = computed(() => store.state.user.chainId)
    const resetMarket = () => store.dispatch("artwork/market/reset")
    const onChainIdChange = () => {
      resetMarket()
      requestMedia()
    }

    watch(chainId, onChainIdChange)

    const load = async () => {
      await loadArtworkFromFirebase(route.params.artworkURI)
      await requestMedia()

      loadLatestSave()
    }

    load()

    watch(
      () => route.params.artworkURI,
      async () => {
        reset()

        await load()
      }
    );

    return {
      artwork,
      media,
      tabs,
      currentTab,
      isComponent,
      changeTab,
    }
  },
})
</script>

<style lang="scss" scoped>
.artwork {
  &__tab-bar {
    @include plain-list;
    background: $gradient-highlight;
    display: flex;
    gap: $border-width;
    grid-area: topbar;
    margin-top: -$border-width;
    padding: $border-width 0;
    position: sticky;
    top: calc($header-height - $border-width);
    z-index: 11;
    &__item {
      background: $primary-color;
      color: $primary-background;
      cursor: pointer;
      font-weight: bold;
      line-height: 1;
      mix-blend-mode: darken;
      padding: 1.5 * $margin-small $margin-small;
      text-align: center;
      width: 100%;
      &.is-active {
        mix-blend-mode: unset;
      }
    }
  }
  &__tab-container {
    grid-area: container;
  }
  @media (min-aspect-ratio: 1/1) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "topbar"
      "container";
    &__details {
      display: none;
    }
  }
}
</style>
