<template>
  <div class="options-tab" v-if="false">
    <div
      ref="guiContainer"
      class="gui-container"
    >
      <!-- gui goes here -->
    </div>
    <div
      v-if="hasCredentials"
      class="save-and-load buttons"
    >
      <div class="button load">
        <button
          :class="loadFeedback.state"
          @click="loadHandler"
        >
          {{ loadFeedback.label }}
        </button>
      </div>
      <div class="button save">
        <button
          :class="saveFeedback.state"
          @click="saveHandler"
        >
          {{ saveFeedback.label }}
        </button>
      </div>
      <!-- <div v-if="!artworkOptions"> No options available ... yet </div> -->
    </div>
    <div
      v-else-if="hasAccount"
      class="verify-notice"
    >
      <p>To save and load please verify your account</p>
      <div class="button verify">
        <button @click="signInViaMetaMask">
          Verify
        </button>
      </div>
    </div>
    <div
      v-else
      class="verify-notice"
    >
      <p>To save and load please connect to metamask and verify your account</p>
      <div class="button connect-and-verify">
        <button @click="connectAndSignInViaMetaMask">
          Connect and Verify
        </button>
      </div>
    </div>

    <div
      v-if="latestLoad"
      class="save-info"
    >
      <h3 class="title">
        Current Save
      </h3>
      <eth-address
        class="no-pop-up small"
        :eth-address="latestLoad.ethAddress"
      />
      <p class="save-last-updated">
        Last updated: <span class="value">{{ saveTimestamp }}</span>
      </p>
      <p class="save-id">
        ID: <a
          :href="saveURL"
          class="save-link"
        >{{ latestLoad.id }}</a>
      </p>
    </div>
  </div>
  <h4 class="coming-back-soon" v-else>We're busy making improvments.<br/>This section is coming back soon!</h4>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue'
import * as dat from 'dat.gui'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import EthAddress from './EthAddress.vue'

export default defineComponent({
  name: 'options',
  components: {
    EthAddress
  },
  props: {
    artwork: Object
  },
  setup() {
    //TODO: save options (just date gui)
    //TODO: save optins that are not in dat.gui (position for example)
    //TODO: load in save and update dat.gui options and comlink to artwork

    const store = useStore()
    const hasAccount = computed(() => store.getters['user/hasAccount'])
    const hasCredentials = computed(() => store.getters['user/hasCredentials'])
    const signInViaMetaMask = () => store.dispatch('user/signInViaMetaMask')
    const connectAndSignInViaMetaMask = () => store.dispatch('user/connectAndSignInViaMetaMask')
    // const artworkSave = computed(() => store.state.artwork.optionsV2.save)
    const artworkControllers = computed(() => store.state.artwork.optionsV2.controllers)
    const onControllerUpdate = (payload) => store.dispatch('artwork/optionsV2/onControllerUpdate', payload)
    // const guiObject = computed(() => store.state.artwork.optionsV2.guiObject)
    const latestLoad = computed(() => store.state.artwork.optionsV2.latestLoad)
    // console.log(latestLoad)
    // GUI

    const renderGui = (_controllers, onControllerUpdateHandler) => {
      // TODO: comupute width
      const datGui = new dat.GUI({ autoPlace: false, width: 300 })

      const createControllerObject = (c) => {
        const cO = {}
        for(const [key, controller] of Object.entries(c)){
          cO[key] = controller.value
        }
        return cO
      }

      // create object for dat.gui to manipulate
      const controllersObject = latestLoad.value ?
        {...createControllerObject(_controllers), ...latestLoad.value.data} :
        createControllerObject(_controllers)

      const cKeys = Object.keys(_controllers)
      const controllers = cKeys.map(key => {
        const {min, max, step, label} = _controllers[key]
        const controller = datGui.add(controllersObject, key, min, max, step)

        if(label) controller.name(label)

        controller.listen()
        controller.onChange(() => {
          onControllerUpdateHandler({
            property: controller.property,
            value: controller.getValue()
          })
        })
        return controller
      })

      return { datGui, controllers, controllersObject }
    }

    const removeGui = (gui) => {
      if(gui.value !== null){
        gui.value.destroy()
        gui.value = null
      }
    }

    const gui = ref(null)
    const guiContainer = ref()

    const ethAddress = computed(() => store.state.user.account)
    const artwork = computed(() => ({uid: store.getters['artwork/getId']}))
    const slot = '0'

    const saveOptions = () => store.dispatch('artwork/optionsV2/save')
    const saveFeedback = reactive({
      state: 'not',
      label: 'save'
    })
    const saveHandler = async () => {
      saveFeedback.state = 'pending'
      saveFeedback.label = 'saving...'
      await saveOptions()
      saveFeedback.state = 'complete'
      saveFeedback.label = 'saved'
      setTimeout(() => {
        saveFeedback.state = 'not'
        saveFeedback.label = 'save'
      }, 2000)
    }

    const loadOptions = () => store.dispatch('artwork/optionsV2/loadFromFirebase', {
      ethAddress: ethAddress.value,
      artwork: artwork.value,
      slot,
      cache: true
    })
     const loadFeedback = reactive({
      state: 'not',
      label: 'load'
    })
    const loadHandler = async () => {
      loadFeedback.state = 'pending'
      loadFeedback.label = 'loading...'
      await loadOptions()
      loadFeedback.state = 'complete'
      loadFeedback.label = 'loaded'
      setTimeout(() => {
        loadFeedback.state = 'not'
        loadFeedback.label = 'load'
      }, 2000)
    }

    const saveURL = computed(() => `/artwork/${latestLoad.value.artwork.uri}/${latestLoad.value.id}`)
    dayjs.extend(relativeTime)
    const saveTimestamp = computed(() => {
      if(!latestLoad.value) return
      const date = new Date(latestLoad.value.timestamp.seconds * 1000)
      return dayjs(date).fromNow()
    })

    onMounted(() => {
      // console.log("V2", artworkControllers.value)
      //TODO: request contllers from artwork
      if(artworkControllers.value){
        // console.log( artworkControllers.value, onControllerUpdate)
        const {datGui, controllersObject } = renderGui( artworkControllers.value, onControllerUpdate)
        gui.value = datGui
        store.commit('artwork/optionsV2/setGuiObject', controllersObject)

        gui.value.domElement.style = "width: '100%;"
        guiContainer.value.appendChild(gui.value.domElement)
      }
    })

    onUnmounted(() => {
      removeGui(gui)
    })

    return {
      gui,
      guiContainer,
      loadFeedback,
      loadHandler,
      saveFeedback,
      saveHandler,
      hasCredentials,
      hasAccount,
      signInViaMetaMask,
      connectAndSignInViaMetaMask,
      latestLoad,
      saveURL,
      saveTimestamp
    }
  },
})
</script>

<style lang="scss" scoped>
  .coming-back-soon{
    padding: $margin;
    font-size: 200%;
    @extend %gradient-text;
  }
</style>
