<template>
  <div :class="`artwork-content-container`">
    <ArtworkContent
      class="artwork-content"
      :artwork="artwork"
      @loaded="onArtworkLoad"
      :camera="true"
      :fullscreen="true"
    />
    <Details class="artwork-content__details" :artwork="artwork" :media="media" />
    <ul class="tab-bar">
      <li
        class="tab-bar__item"
        :class="{['--active']: currentTab === tab.name}"
        v-for="tab in tabs"
        :key="tab.label"
        @click="changeTab(tab)"
      >
        <Icon
          class="tab-bar__item__icon"
          :width="'2em'"
          :height="'2em'"
          :stroke-width="3"
          :customViewBox="tab.iconVeiwBox"
        >
          <component :is="tab.icon"></component>
        </Icon>
        {{ tab.label }}
      </li>
    </ul>
    <component :is="currentTab" :media="media" :artwork="artwork" class="content"></component>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import ArtworkContent from "./ArtworkContent.vue"
import Controls from "./Controls.vue"
import Options from "./OptionsV2.vue"
import Details from "./ArtworkDetails.vue"
import Icon from "./Icon.vue"
import IconOptions from "./IconOptions.vue"
import IconAttention from "./IconAttention.vue"

export default defineComponent({
  name: "artwork",
  components: {
    ArtworkContent,
    Controls,
    Options,
    Icon,
    IconOptions,
    IconAttention,
    Details,
  },
  setup() {
    const store = useStore()
    const tabs = ref([
      {
        name: Controls.name,
        label: "controls",
        icon: IconAttention.name,
        iconVeiwBox: "0 0 45 45",
      },
      {
        name: Options.name,
        label: "options",
        icon: IconOptions.name,
        iconVeiwBox: "0 0 85 85",
      },
    ])

    const currentTab = ref(Controls.name)
    const changeTab = tab => currentTab.value = tab.name

    const artwork = computed(() => store.getters["artwork/getData"])
    const media = computed(() => store.state.artwork.market.media)
    // const iframeRef = computed(() => store.state.artwork.iframeRef)

    const onArtworkLoad = () => {
      // NOTE: leaving this here incase i need it later
    }

    return {
      artwork,
      media,
      onArtworkLoad,
      tabs,
      currentTab,
      changeTab,
    }
  },
})
</script>

<style lang="scss" scoped>
.artwork-content {
  grid-area: artwork;
  padding:0;
  max-height: 30em;
  height: 100vw;
  min-height: 15em;
  @media (min-aspect-ratio: 1/1) {
    min-height: calc(100vh - 2 * $header-height);
    max-height: calc(100vh - 2 * $header-height);
    height: auto;
  }
  &__details {
    display: none;
    @media (min-aspect-ratio: 1/1) {
      display: block;
      grid-area: details;
    }
  }
}
.tab-bar {
  grid-area: tabbar;
  @include plain-list;
  display: flex;
  li {
    display: flex;
  }
  &__item {
    width: 100%;
    padding: $margin-small;
    background: $primary-color;
    color: $primary-background;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.--active {
      background: $primary-background;
      color: $primary-color;
    }
    &__icon {
      margin-right: $margin * 0.5;
    }
  }
}
.content {
  grid-area: tab;
  padding: $margin;
}
.artwork-content-container {
  @media (min-aspect-ratio: 1/1) {
    display: grid;
    grid-template-columns: auto 35em;
    grid-template-rows: 18em 50px auto;
    grid-template-areas:
      "artwork details"
      "artwork tabbar"
      "artwork tab";
  }
}
</style>
