<template>
  <div class="details" v-if="artwork">
    <h1 class="details__name">{{ artwork.title }}</h1>
    <div class="details__meta">
      <router-link
      class="details__creator"
      :to="`/profiles/${creator.ethAddress}`"
      >
        <h4 class="details__creator__title">creator</h4>
        <p class="details__creator__id">{{ creator.name }}</p>
      </router-link>

      <router-link
        v-if="owner"
        :to="`/profiles/${owner.id}`"
        class="details__owner"
      >
        <h4 class="details__owner__title">owner</h4>
        <!-- TODO: make user component that retrieves displayname/ens name -->
        <p class="details__owner__id"><EthAddress :ethAddress="owner.id" /></p>
        <!-- <p class="details__owner__id">{{ media.owner.id }}</p> -->
      </router-link>

      <div v-if="soldFor" class="details__sold">
        <h4 class="details__sold__title">sold for</h4>
        <p class="details__sold__amount">{{ formatUnits(soldFor.amount) }} {{ soldFor.currency.symbol }}</p>
        <p class="details__sold__time">{{ formatRelativeTime(soldFor.inactivatedAtTimestamp) }}</p>
      </div>

      <div v-if="media" class="details__provenance">
        <h4 class="details__provenance__title">provenance</h4>
        <a class="details__provenance__etherscan"
          :href="`https://etherscan.io/token/0xabEFBc9fD2F806065b4f3C237d4b59D9A97Bcac7?a=${media.id}`"
        >view on etherscan</a>
      </div>

      <div v-if="isDroppingSoon" class="details__sold">
        <h4 class="details__sold__title">status</h4>
        <p class="details__sold__time">Dropping soon</p>
      </div>
    </div>

    <div class="details__auction" v-if="auction">
      <!-- TODO: add auction detials -->
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"
import EthAddress from "./EthAddress.vue"
import { utils } from "ethers"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export default defineComponent({
  components:{
    EthAddress
  },
  props: {
    artwork: Object,
    media: [Object, Boolean],
    auction: Object
  },
  setup(props) {
    const isDroppingSoon = computed(() => {
      if(props.artwork.zoraMediaId === '') return true
    })
    const soldFor = computed(() => {
      console.log(props.media)
      if(!props.media) return
      if(!props.media.inactiveBids) return
      // has a bid been finalized
      const finalizedBids = props.media.inactiveBids.filter(bid => bid.type === 'Finalized')
      if(!finalizedBids.length) return

      //HACK: should order by date
      return finalizedBids[0]
    })

    const owner = computed(() => {
      if(typeof props.media !== 'object') return
      if(!props.media) return
      if(!props.media.owner) return
      // HACK: show prevOwner if the auction holds it
      const auctionContract = '0xe468ce99444174bd3bbbed09209577d25d1ad673'
      if(props.media.owner.id === auctionContract) return props.media.prevOwner
      return props.media.owner
    })


    const creator = computed(() => {
      if(!props.artwork) return
      if(!props.artwork.artist) return
      let creator = {...props.artwork.artist}

      // no eth address fallback
      if(!props.artwork.artist.ethAddress) {
        creator.ethAddress = creator.uri
      }

      return creator
    })


    dayjs.extend(relativeTime)
    const formatRelativeTime = (timestamp) => dayjs(parseInt(timestamp) * 1000).fromNow()
    return {
      soldFor,
      formatUnits: utils.formatUnits,
      formatRelativeTime,
      isDroppingSoon,
      creator,
      owner
    }
  }
})
</script>

<style lang="scss" scoped>
  .details{
    background: $primary-color;
    color: $primary-background;
    padding: $margin;

    &__name{
      margin: $margin-small 0;
      // stop overflowing with long words hack
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    &__meta {
      display: grid;
      gap: $margin;
      grid-template-columns: 1fr 1fr;
      // gap: $margin-small;
      overflow: hidden; // stop long names messing things up
    }
    &__creator, &__owner{
      flex-grow: 1;
      position: relative;
      z-index: 2; // make clickable
      @include plain-link($primary-background);
      &__title{
        margin: 0;
      }
      &__id{
        margin: 0; 
        @include plain-link($primary-background);
        text-decoration: underline;
      }
      &:hover{
      }
    }
    &__sold{
      &__title{
        margin: 0;
      }
      &__amount{
        margin: 0;
      }
      &__time{
        margin: 0;
        opacity: 0.7;
        font-size: 80%;
      }
    }
    &__provenance{
      &__title{
        margin: 0;
      }
      &__etherscan{
        margin: 0;
        @include plain-link($primary-background);
        text-decoration: underline;
      }
    }
  }
</style>

<style lang="scss">
.details a {
  color: $primary-background;
}
</style>