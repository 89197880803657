<template>
  <g>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="62.144" y1="23.388" x2="69.513" y2="23.388"/>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="15.487" y1="23.388" x2="44.111" y2="23.388"/>
      <circle fill="none" stroke-linejoin="round" stroke-miterlimit="10" cx="55.662" cy="23.388" r="6.05"/>
  </g>
  <g>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="55.318" y1="61.611" x2="69.513" y2="61.611"/>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="15.487" y1="61.611" x2="37.485" y2="61.611"/>
      <circle fill="none" stroke-linejoin="round" stroke-miterlimit="10" cx="48.837" cy="61.611" r="6.051"/>
  </g>
  <g>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="23.111" y1="42.499" x2="15.487" y2="42.499"/>
      <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="69.513" y1="42.499" x2="41.111" y2="42.499"/>
      <circle fill="none" stroke-linejoin="round" stroke-miterlimit="10" cx="29.593" cy="42.499" r="6.051"/>
  </g>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "icon-options"
})
</script>
