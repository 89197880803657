<template>
  <div class="info" v-if="artwork">
    <div
      class="info__description"
      v-if="description"
      v-html="description"
    />
    <!-- <p class="info__date-published">Published: {{ datePublished }}</p> -->
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"
import { useStore } from "vuex"
import marked from 'marked'
import DOMPurify from 'dompurify'

export default defineComponent({
  name: "info",
  setup() {
    const store = useStore()
    const artwork = computed(() => store.getters["artwork/getData"])
    const convertToHtml = (markdown) => {
      if(!markdown) return
      const converted = marked(markdown, { breaks: true })
      return DOMPurify.sanitize(converted, {ALLOWED_TAGS: ['a', 'p', 'strong', 'em', 'h1', 'h2', 'h3', 'h4', 'br']})
    }

    const description = computed(() => {
      if(!artwork.value) return
      return convertToHtml(artwork.value.description)
    })

    const datePublished = computed(() => {
      if(!artwork.value) return
      const date = artwork.value.published.toDate()
      // TODO: find a libary that formats the date nicley for different countries
      // HACK:formate for uk date
      return `${date.getDate()}\\${date.getMonth()}\\${date.getFullYear()}`
    })
    return {
      artwork,
      description,
      datePublished
    }
  },
})
</script>

<style lang="scss" scoped>
.info{
  padding: $margin;
  max-width: 600px;
}
</style>