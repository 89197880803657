<template>
  <div class="buy" v-if="auction">
    <h1>Auction</h1>
    <ul class="buy__details">
      <li
        v-for="detail in details"
        :key="detail"
        class="buy__detail"
      >
        <h5 class="buy__detail__heading">{{ detail.label }}</h5>
        <p class="buy__detail__content">{{ detail.value }}</p>
      </li>
    </ul>
    <div class="buy_auction">
      <div class="actions">
        <div v-if="!isEnded && auction.status === 'Active'" class="place-bid">
          <label class="place-bid__label" :class="{['--disabled']: isBidFormDisabled}" for="amount">amount</label>
          <div class="place-bid__input-container">
            <input
              class="place-bid__input"
              type="number"
              min="0"
              step="any"
              name="amount"
              v-model="bidAmount"
              :disabled="isBidFormDisabled"
            />
            <span class="place-bid__currency">ETH</span>
          </div>
          <button
            class="place-bid__button"
            :disabled="isBidFormDisabled"
            @click="() => placeBid(auction.id, bidAmount)"
            >place bid
          </button>
          <p class="place-bid__status" v-if="bidStatus">{{ bidStatus }}</p>
          <p class="place-bid__tx" v-if="bidTx"><a :href="`https://etherscan.io/tx/${bidTx}`" target="_blank">view on etherscan</a></p>
          <p class="place-bid__instructions" v-if="!isBidFormDisabled">{{ bidInstructions }}</p>
          <br/>
        </div>
        <div v-else-if="isEnded === true" class="buy__end-auction">
          <p>The auction has reached it's end</p>
          <button
            class="buy__end-auction__button"
            @click="endAuction"
          >
            End Auction
          </button>
        </div>
      </div>
      <div class="buy__bids">
        <div class="buy__bids__current" v-if="auction.currentBid">
          <button @click="refresh" class="refresh">refresh</button>
          <h4 class="buy__bids__current__title">highest bid</h4>
          <div class="bid --feature">
            <p class="bid__amount">{{ formatUnits(auction.currentBid.amount) }} ETH</p>
            <p class="bid__bidder"><EthAddress :ethAddress="auction.currentBid.bidder.id" /></p>
            <p class="bid__relative-time">{{ formatRelativeTime(auction.currentBid.createdAtTimestamp) }}</p>
            <p class="bid__tx"><a :href="`https://etherscan.io/tx/${auction.currentBid.transactionHash}`" target="_blank">etherscan</a> ></p>
          </div>
        </div>
        <ul class="buy__bids__previous" v-if="previousBids">
          <h4 class="buy__bids__previous__title">previous bids</h4>
          <li
            v-for="bid in previousBids"
            :key="bid.transactionHash"
            class="bid"
          >
            <p class="bid__amount">{{ formatUnits(bid.amount) }} ETH</p>
            <p class="bid__bidder"><EthAddress :ethAddress="bid.bidder.id"/></p>
            <p class="bid__relative-time">{{ formatRelativeTime(bid.createdAtTimestamp) }}</p>
            <p class="bid__tx"><a :href="`https://etherscan.io/tx/${bid.transactionHash}`" target="_blank">etherscan</a> ></p>
          </li>
        </ul>
      </div>
    </div>
    <p class="view-on-zora" ><a :href="`https://zora.co/collections/zora/${media.id}`" target="_blank">View on Zora</a> ></p>
  </div>
   <!-- No Media -->
  <div class="dropping-soon" v-else-if="!media">
    <h2>Dropping Soon!</h2>
    <p>connect with us to keep updated</p>
    <p><a href="https://discord.gg/wnj2qW8pH6" target="_blank" rel="noopener">
      Discord &rsaquo;
    </a></p>
    <p><a href="https://twitter.com/oltaart" target="_blank" rel="noopener">
      Twitter &rsaquo;
    </a></p>
  </div>
  <!-- Loading -->
  <div class="loading" v-else-if="auction === null">Loading...</div>
  <!-- No Auction -->
  <div class="no-auction" v-else-if="media">
    <div class="no-auction__details">
      <div v-if="soldFor" class="no-auction__details__sold">
        <h4 class="no-auction__details__sold__title">sold for</h4>
        <p class="no-auction__details__sold__amount">{{ formatUnits(soldFor.amount) }} {{ soldFor.currency.symbol }}</p>
        <p class="no-auction__details__sold__time">{{ formatRelativeTime(soldFor.inactivatedAtTimestamp) }}</p>
      </div>
      <router-link
        v-if="owner"
        :to="`/profiles/${owner.id}`"
        class="no-auction__details__owner"
      >
        <h4 class="no-auction__details__owner__title">congrats</h4>
        <!-- TODO: make user component that retrieves displayname/ens name -->
        <p class="no-auction__details__owner__id"><EthAddress :ethAddress="owner.id" /></p>
        <!-- <p class="details__owner__id">{{ media.owner.id }}</p> -->
      </router-link>
    </div>
    <br/>
    <p class="view-on-zora" >You can still make an offer on zora <br/><a :href="`https://zora.co/collections/zora/${media.id}`" target="_blank">View on Zora</a> ></p>
    
    <p class="coming-back-soon">We're busy making improvments.
This section is coming soon!</p>
  </div>
</template>

<script>

import { defineComponent, ref, onMounted, computed, watch, reactive} from 'vue'
import { AuctionHouse } from '@zoralabs/zdk'
import { zoraGraph, zoraGraphRinkeby, getSigner, getChainId } from '../services.js'
import { gql } from 'graphql-request'
import { utils, BigNumber } from 'ethers'
import EthAddress from './EthAddress.vue'
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

export default defineComponent({
  name: "buy-section",
  props: {
    media: Object
  },
  components: {
    EthAddress
  },
  setup(props) {
    dayjs.extend(relativeTime)
    dayjs.extend(duration)
    
    // TODO: put back after rinkyby testing
    const mediaId = props.media.id // NFT id will be a prop
    // const mediaId = '2142' // NFT id will be a prop
    const auction = ref(null)

    const soldFor = computed(() => {
      if(!props.media) return
      if(!props.media.inactiveBids) return
      // has a bid been finalized
      const finalizedBids = props.media.inactiveBids.filter(bid => bid.type === 'Finalized')
      if(!finalizedBids.length) return

      //HACK: should order by date
      return finalizedBids[0]
    })

    const owner = computed(() => {
      if(typeof props.media !== 'object') return
      if(!props.media) return
      if(!props.media.owner) return
      // HACK: show prevOwner if the auction holds it
      const auctionContract = '0xe468ce99444174bd3bbbed09209577d25d1ad673'
      if(props.media.owner.id === auctionContract) return props.media.prevOwner
      return props.media.owner
    })

    const AuctionHouseFromSigner = async () => {
      const signer = await getSigner()
      const chainId = await signer.getChainId()

      return new AuctionHouse(signer, chainId)
    }

    const social = reactive([
      {
        label: "Twitter",
        url: "https://twitter.com/oltaart",
      },
      {
        label: "Instagram",
        url: "https://instagram.com/olta.art/",
      },
      {
        label: "Cent",
        url: "https://beta.cent.co/hool/",
      },
    ])

    const getActiveAuction = async (tokenId) => {
      const query =  gql`{
        reserveAuctions(where: {
            tokenId: ${tokenId},
            status_in: [Active, Pending]
        }){
          id
          status
          approved
          duration
          expectedEndTimestamp
          tokenOwner {
            id
          }
          tokenId
          curator {
            id
          }
          reservePrice
          auctionCurrency{
            name
            symbol
            decimals
            id
          }
          currentBid{
            bidder {
              id
            }
            amount
            createdAtTimestamp
            transactionHash
          }
          previousBids {
            bidder {
              id
            }
            amount
            createdAtTimestamp
            transactionHash
          }
        }
      }`

      const makeQuery = async (query, graphClient = zoraGraph) => {
        const resp = await graphClient.request(query)
        if(!resp) return
        if(!resp.reserveAuctions || resp.reserveAuctions.length === 0) return

        return resp.reserveAuctions[0]
      }

      try{
        // Check for rinkyby testnet
        const signer = await getSigner()
        const chainId = await signer.getChainId()
        if(chainId === 4){
          return await makeQuery(query, zoraGraphRinkeby)
        }
      } catch (error) {
        console.log("no metamask")
      }

      return await makeQuery(query)
    }

    const refresh = async () => {
      if(!mediaId) return
      auction.value = await getActiveAuction(mediaId)
      bidAmount.value = utils.formatUnits(defaultBid.value)
      durationLeft.value = calcDurationLeft()
    }
    const defaultBid = computed(() => {
      if(!isReserveMet.value) return auction.value.reservePrice
      const currentBid = BigNumber.from(auction.value.currentBid.amount)
      const hundred = utils.parseUnits('100')
      const five = utils.parseUnits('5')
      const fivePercent = currentBid.mul(five).div(hundred)
      const result = currentBid.add(fivePercent)
      return result
    })
    const bidAmount = ref(0)
    const bidStatus = ref('')
    const bidTx = ref('')
    const isBidFormDisabled = computed(() => bidStatus.value !== '')
    const placeBid = async (auctionId, amount) => {
      if (!auctionId) return
      try{
        bidStatus.value = `placing bid... please check your metamask`
        const auctionHouse = await AuctionHouseFromSigner()
        const amountBN =  utils.parseUnits(String(amount))
        const resp = await auctionHouse.createBid(auctionId, amountBN)
        console.log(resp)
        bidStatus.value = `Confirming bid... this may take a few minutes`
        bidTx.value = resp.hash
        await resp.wait()
        bidStatus.value = `Bid Placed`
        setTimeout( () => {
          bidStatus.value = ''
          refresh()
        },2000)
      } catch (error) {
        console.log(error)
        //HACK: extract contract error message from metamask error
        const start = error.message.search(`"message":`) + 10
        const end = error.message.indexOf(`,`, start)
        const contractError = error.message.slice(start, end)
        bidStatus.value = `ERROR ${contractError}`
        setTimeout(() => {
          bidStatus.value = ''
          refresh()
        },5000)
      }
    }
    const bidInstructions = computed(() => {
      if(!isReserveMet.value) return `Bid must be at least ${utils.formatUnits(defaultBid.value)} ETH\nThe first bid has to meet the reserve price`
      return `Bid must be at least ${utils.formatUnits(defaultBid.value)} ETH\nThe next bid must be 5% more than the current bid`
    })

    const isEnded = computed(() => {
      if (!auction.value) return
      if(auction.value.expectedEndTimestamp === null) return

      return auction.value.expectedEndTimestamp < Date.now() / 1000
    })

    const endAuction = async () => {
      if(!isEnded.value) return

      const auctionHouse = await AuctionHouseFromSigner()
      const resp = await auctionHouse.endAuction(auction.value.id)
      resp.wait()
      console.log("auction ended")
    }

    const isReserveMet = computed(() =>{
      if(!auction.value) return
      console.log(auction.value.currentBid )
      return auction.value.currentBid ? true : false
    })

    // Timer
    const timer = ref()
    const durationLeft = ref()
    const calcDurationLeft = () => {
      if(!auction.value) return
      return dayjs(auction.value.expectedEndTimestamp * 1000) - dayjs()
    }
    watch([durationLeft], () => {
      if(!auction.value) return
      if(!auction.value.expectedEndTimestamp) return

      if(durationLeft.value < 0) {
        timer.value = 'Ended'
        return
      }

      // over a day just display days left
      const daysLeft = dayjs.duration(durationLeft.value).asDays()
      if(daysLeft > 1) {
        timer.value = dayjs.duration(durationLeft.value).humanize(true)
        return
      }

      // start countdown ticker
      setTimeout(() => {
        durationLeft.value = calcDurationLeft()
      }, 1000)
      timer.value = dayjs.duration(durationLeft.value).format("HH[h] mm[m] ss[s]")
    })

    // Details
    const createDetail = (label, value) => ({label, value})
    const details = computed(() => {
      if(!auction.value) return
      console.log(props.media.creatorBidShare)
      const status = () => {
        if(auction.value.status === "Pending") return "Starting 30th Nov 8pm GMT"
        if((auction.value.status === "Active") && (!isReserveMet.value)) return "Starts when reserve is met"
        return auction.value.status
      }
      const duration = () => {
        if(isReserveMet.value) return
        return dayjs.duration(auction.value.duration * 1000).format("DD[d] HH[h]")
      }
      const details = [
        createDetail("status", status()),
        createDetail("time left", timer.value),
        createDetail("duration", duration()),
        createDetail("reserve", utils.formatUnits(auction.value.reservePrice) + " ETH"),
        createDetail("token id", auction.value.tokenId),
        createDetail("artist royalties", utils.formatUnits(props.media.creatorBidShare) + " %")
      ]
      return details.filter(detail => detail.value)
    })

    // Bid formating
    const formatRelativeTime = (timestamp) => dayjs(parseInt(timestamp) * 1000).fromNow()

    const previousBids = computed(() => {
      if(!auction.value) return
      if(!auction.value.previousBids || auction.value.previousBids.length === 0) return

      // order by date
      auction.value.previousBids.sort((a, b) => {
        return a.createdAtTimestamp - b.createdAtTimestamp
      })

      return auction.value.previousBids.reverse()
    })

    onMounted(async () => {
      if(!mediaId) return
      // Check for rinkyby testnet
      auction.value = await getActiveAuction(mediaId)
      if(!auction.value) return

      // setup forms
      bidAmount.value = utils.formatUnits(defaultBid.value)
      durationLeft.value = calcDurationLeft()
    })

    return {
      auction,
      formatUnits: utils.formatUnits,
      bidAmount,
      placeBid,
      refresh,
      bidStatus,
      isEnded,
      endAuction,
      details,
      formatRelativeTime,
      bidInstructions,
      bidTx,
      previousBids,
      isBidFormDisabled,
      soldFor,
      owner
    }
  },
})
</script>

<style lang="scss" scoped>
  .buy{
    padding: $margin;
    max-width: 35em;
    margin: auto;
    &__details{
      @include plain-list;
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
      gap: $margin;
    }
    &__detail {
      &__heading{
        margin: 0;
        font-size: 100%;
      }
      &__content{
        margin: 0;
      }
    }
  }

  .place-bid{
    margin-top: $margin;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    &__label{
      font-weight: bold;
      &.--disabled{
        color: $tertiary-color;
      }
    }
    &__status, &___tx{
      margin: 0;
      font-weight: bold;
    }
    &__input-container{
      margin: 0 0 $margin-small 0;
    }
    &__input{
      padding: $margin-small;
      border-radius: 0;
      border-style: solid;
      border-color: black;
      border-width:$border-width;
      font-family: inherit;
      font-size: 120%;
      &:disabled{
        border-color: $tertiary-color;
      }
    }

    &__currency{
      padding-left: $margin-small;
    }
    &__button{
      @include plain-link;
      cursor: pointer;
      width: fit-content;
      font-weight: bold;
      padding: $margin-small $margin-small * 2;
      margin-bottom: $margin-small;
      display: block;
      font-size: 120%;
      background: $gradient-highlight;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      color: $primary-background;
      border: none;
      &:disabled{
        opacity: 0.5;
        cursor: default;
      }
      &:hover{
        background: $gradient-highlight;
        background-clip: initial;
        -webkit-text-fill-color: initial;
        color: $primary-background;
      }
    }
    &__instructions{
      white-space: pre-line;
      color: $tertiary-color;
      font-size: 90%;
    }
  }

  .actions{
    margin: $margin*2 0;
  }

  .buy__bids {
    &__previous{
      @include plain-list;
      max-width: 35em;
      &__title{
        margin: $margin 0 0 0;
        border-bottom: $tertiary-color solid $border-width;
      }
      & .bid {
        display: flex;
      }
    }
    &__current{
      position: relative;
       max-width: 35em;
       &__title{
         margin: 0;
       }
       &__amount{
         font-weight: bold;
       }
    }
  }

  .refresh{
    position: absolute;
    right: 0;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: none;
    margin: 0 -$margin-small;
    padding: $margin-small;
  }

  .bid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $margin-small;
    border-bottom: $tertiary-color solid $border-width;
    padding: $margin-small 0;
    &.--feature{
      @extend %gradient-border;
      border-style: solid;
      border-width: $border-width;
      padding: $margin-small 0;
      & .bid__amount {
        font-size: 1.8em;
        font-weight: bold;
      }
    }
    &__amount{
      margin: 0;
      padding: 0 $margin-small;
      flex-grow: 1;
      width: 100%;
    }
    &__bidder{
      width: 100%;
    }
    &__tx{
      text-align: right;
    }
    &__relative-time{
      color: $tertiary-color;
    }
    &__bidder, &__relative-time, &__tx{
      margin: 0;
      flex-grow: 1;
      padding: 0 $margin-small;
    }
  }

  .loading{
    padding: $margin;
    font-size: 120%;
  }

  .no-auction{
    padding: $margin;
    max-width: 30em;
    width: calc(100% - $margin*2);
    margin:auto;
    &__details{
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
      &__creator, &__owner{
        flex-grow: 1;
        position: relative;
        z-index: 2; // make clickable
        @include plain-link($primary-color);
        &__title{
          margin: 0;
        }
        &__id{
          margin: 0;
          @include plain-link($primary-color);
          text-decoration: underline;
        }
      }
      &__sold{
        &__title{
          margin: 0;
        }
        &__amount{
          margin: 0;
        }
        &__time{
          margin: 0;
          opacity: 0.7;
          font-size: 80%;
        }
      }
    }
  }

  .coming-back-soon{
    // padding: $margin;
    font-weight: bold;
    font-size: 120%;
    margin-top: $margin * 2;
    @extend %gradient-text;
  }

  .view-on-zora{
    margin: $margin 0;
  }

  .dropping-soon{
    padding: $margin;
  }
</style>
